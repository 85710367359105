import {useRouter} from 'next/router';
import {useEffect} from 'react';

import {paths} from '@/common/enums';
import {useAuth} from '@/hooks/useAuth';

const Index = (): null => {
  const router = useRouter();
  const {user} = useAuth();

  useEffect(() => {
    if (typeof user !== 'undefined') {
      if (user) {
        router.push(paths.PROJECTS);
      } else {
        router.push(paths.LOGIN);
      }
    }
  });

  return null;
};

export default Index;
